import React, { useEffect, useState } from "react";

//Import Material-UI Components
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

//Import Helper Functions
import {
	getUnique,
	where,
} from "../../utilities/HelperFunctions/helperFunctions";

//Import Components
import BrIframeModal from "./BrIframeModal";

//Import Form
import MarketoForm from "../../utilities/MarketoForms/MarketoForm";

const useStyles = makeStyles((theme) => ({
	text: {
		fontFamily: theme.typography.body1.fontFamily,
		textAlign: "left",
		color: "#494949",
		margin: 0,
		padding: 0,
		listStyle: "none",
	},
	tripDatesTitle: {
		textAlign: "left",
		fontFamily: theme.typography.h1.fontFamily,
		fontWeight: 300,
		fontSize: "20px !important",
		marginTop: 0,
		color: "#fff",
		textTransform: "uppercase",
		borderBottom: "1px solid #fff",
	},
	departureImageContainer: {
		height: "100%",
	},
	departureImage: {
		objectFit: "cover",
		width: "100%",
		height: "100%",
		minWidth: "305px",
	},
	tripSchedule: {
		fontFamily: theme.typography.h1.fontFamily,
		textAlign: "left",
		fontSize: "14px",
		color: "#494949",
		marginBottom: "10px",
		marginTop: "30px",
		fontWeight: 300,
	},
	daysOfTheWeek: {
		margin: "0px 0px 5px 5px",
		display: "flex",
		maxWidth: "128px",
		justifyContent: "space-between",
		textAlign: "center",
		fontSize: "13px",
		fontFamily: theme.typography.h1.fontFamily,
		fontWeight: 600,
	},
	departureDays: {
		"& .active-departure-day": {
			width: "18px",
			height: "18px",
			marginRight: "2px",
			marginBottom: "2px",
			backgroundColor: "#00416B",
			color: "#fff",
			fontSize: "12px",
			textAlign: "center",
			fontFamily: theme.typography.h1.fontFamily,
		},
		"& .active-departure-day.limited-edition": {
			width: "18px",
			height: "18px",
			marginRight: "2px",
			marginBottom: "2px",
			backgroundColor: "#6C705C",
			color: "#fff",
			fontSize: "12px",
			textAlign: "center",
			fontFamily: theme.typography.h1.fontFamily,
		},
		"& .inactive-departure-day": {
			width: "18px",
			height: "18px",
			marginRight: "2px",
			marginBottom: "2px",
			backgroundColor: "transparent",
			fontSize: "12px",
			textAlign: "center",
			border: "1px solid #00416B",
			color: "#494949",
			fontFamily: theme.typography.h1.fontFamily,
		},
		"& .inactive-departure-day.limited-edition": {
			width: "18px",
			height: "18px",
			marginRight: "2px",
			marginBottom: "2px",
			backgroundColor: "transparent",
			fontSize: "12px",
			textAlign: "center",
			border: "1px solid #6C705C",
			color: "#494949",
			fontFamily: theme.typography.h1.fontFamily,
		},
	},
	bookCta: {
		background: "#00416B",
		color: "#fff",
		width: "160px",
		lineHeight: 1.2,
		padding: "10px",
		margin: "15px 0px 12px 0px",
		"&:hover": {
			background: "#00416B",
			color: "#fff",
		},
		"&.limited-edition": {
			background: "#6C705C",
			color: "#fff",
			"&:hover": {
				background: "#6C705C",
				color: "#fff",
			},
		},
	},
	requestItineraryCta: {
		display: "inline",
		textTransform: "none",
		textDecoration: "underline",
		padding: 0,
		fontSize: "13.5px",
		position: "relative",
		top: "-1px",
		color: "#00416B",
		"&:hover": {
			boxShadow: "none !important",
			backgroundColor: "transparent !important",
			color: "#00416B",
			textDecoration: "underline",
		},
	},
	newsletterSignupCta: {
		display: "inline",
		textTransform: "none",
		textDecoration: "underline",
		padding: 0,
		fontSize: "13.5px",
		position: "relative",
		top: "-1px",
		minWidth: "0",
		"&:hover": {
			boxShadow: "none !important",
			backgroundColor: "transparent !important",
			color: "#00416B",
			textDecoration: "underline",
		},
	},
	bespokeTripCta: {
		"& > a": {
			display: "inline",
			textDecoration: "underline",
			color: "#00416B",
			"&:hover": {
				color: "#00416B",
				textDecoration: "underline",
			},
		},
	},
	youngAdultDepartureButton: {
		background: "#fff",
		border: "1px solid #e6e6e6",
		borderRadius: "10px",
		color: "#494949",
		width: "100%",
		"&:hover": {
			background: "#00416B !important",
			border: "1px solid #00416B !important",
			color: "#fff !important",
		},
		"&.Mui-disabled": {
			border: "none",
		},
	},
	bookingToolsTitle: {
		color: "#494949",
		fontSize: "32px",
		textTransform: "none",
		textAlign: "center",
		fontFamily: theme.typography.h1.fontFamily,
		fontWeight: 300,
		marginTop: "45px",
		marginBottom: "30px",
	},
	departureTypeTitles: {
		textTransform: "none",
		margin: 0,
		lineHeight: 1.4,
	},
	departureTypeToggle: {
		background: "#e6e6e6",
		margin: "auto",
		borderRadius: "10px",
		display: "inherit",
		marginBottom: "20px",
		"& button": {
			borderRadius: "10px !important",
			border: "none",
			"&:first-child": {
				marginRight: "6px",
			},
			"&:hover": {
				boxShadow: "none !important",
			},
		},
		"& .MuiToggleButton-label": {
			display: "block",
			textAlign: "center",
			"& h1": {
				color: "#494949 !important",
			},
		},
		"& .MuiToggleButton-root.Mui-selected": {
			backgroundColor: "#00416B",
			"& h1": {
				color: "#fff !important",
			},
			"&:first-child": {
				boxShadow: "3px 0px 2px #494949 !important",
			},
			"&:nth-of-type(2)": {
				boxShadow: "-3px 0px 2px #494949 !important",
			},
		},
		"& .MuiToggleButton-root": {
			color: "#494949",
			backgroundColor: "#E6E6E6",
		},
		"&.limited-edition .MuiToggleButton-root.Mui-selected": {
			backgroundColor: "#6C705C",
			color: "#fff !important",
		},
	},
	departureYearToggle: {
		justifyContent: "center",
		width: "100%",
		"& button": {
			backgroundColor: "transparent !important",
			paddingTop: "0px",
			paddingBottom: "0px",
			borderTop: "none",
			borderLeft: "none",
			borderRight: "none",
			borderRadius: "0 !important",
			"& > .MuiToggleButton-label": {
				fontSize: "16px",
				color: "#494949",
			},
			"&:hover": {
				boxShadow: "none !important",
			},
		},
		"& .MuiToggleButton-root.Mui-selected": {
			color: "#00416B !important",
			borderBottom: "2px solid #00416B",
			"& > .MuiToggleButton-label": {
				color: "#00416B",
				fontWeight: 600,
				fontSize: "18px",
			},
		},
		"&.limited-edition > button > .MuiToggleButton-label": {
			color: "#6C705C !important",
		},
		"&.limited-edition > button.MuiToggleButton-root.Mui-selected": {
			color: "#6C705C !important",
			borderBottom: "2px solid #6C705C",
		},
	},
	departureFlagCircle: {
		width: "15px",
		height: "15px",
		borderRadius: "50%",
		display: "inline-block",
		position: "relative",
		top: "2px",
	},
	departurePanel: {
		borderRadius: "10px !important",
		marginBottom: "8px",
		border: "1px solid #e6e6e6",
		boxShadow: "none",
		"& .MuiAccordionSummary-root": {
			minHeight: "37px",
		},
		"& .MuiAccordionSummary-content": {
			margin: "8px 0px",
		},
		"& .MuiAccordionSummary-expandIcon": {
			padding: "0px 12px",
		},
		"& .MuiAccordionSummary-root.Mui-expanded": {
			minHeight: "45px",
			borderBottom: "2px solid #e6e6e6",
			maxWidth: "95%",
			margin: "auto",
		},
		"&:before": {
			display: "none",
		},
	},
	panelLabel: {
		fontFamily: theme.typography.h1.fontFamily,
		fontSize: "14px",
		color: "#494949",
	},
	pagination: {
		display: "flex",
		maxWidth: "290px",
		margin: "auto",
		paddingLeft: 0,
		textAlign: "center",
		justifyContent: "center",
		bottom: "40px",
		left: "50%",
		"& li": {
			listStyle: "none",
			border: "1px solid #e6e6e6",
			width: "18px",
			height: "18px",
			marginRight: "16px",
			color: "#494949",
			fontFamily: theme.typography.h1.fontFamily,
			fontSize: "12px",
			"&.active": {
				background: "#00416B",
				color: "#fff",
			},
			"&:hover": {
				cursor: "pointer",
			},
		},
	},
	imageGrid: {
		"& img": {
			borderTopRightRadius: "4px",
			borderBottomRightRadius: "4px",
		},
	},
}));

const buildDepartureData = (tripDepartures) => {
	//Set current date
	const today = new Date();

	//Set Month Names in 'mmm' Format
	const monthNames = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];

	//Initialize departures array and push start/end dates
	let departures = [];

	tripDepartures.map((departure) => {
		//If is active departure (aka set to "reserved" in p15)
		if (departure.statuscode == 6) {
			departures.push({
				departureId: departure.p15_tripdeparturesid,
				bookedGuests: departure.p15_bookedguestcount,
				singleSupplement: departure.cms_departure_single_supplement,
				upgradesAvailable: departure.br_suiteupgradesavailable,
				guaranteedDeparture: departure.br_guaranteeddeparture,
				price: departure.p15_fromprice,
				departureItineraryId: departure.br_departureitineraryid,
				startDate: new Date(departure.p15_startdate),
				endDate: new Date(departure.p15_enddate),
				youngAdult: departure.br_youngadult,
				privatized: departure.br_blockeddeparture,
				maxGuests: departure.p15_maxguests,
				onlyAvailableToBePrivatized:
					departure.p15_bookedguestcount === 0 &&
					departure.br_blockeddeparture == true
						? true
						: false,
			});
		}
	});

	//Sort departures by start date
	departures.sort((a, b) => {
		let fa = a.startDate,
			fb = b.startDate;

		if (fa < fb) {
			return -1;
		}
		if (fa > fb) {
			return 1;
		}
		return 0;
	});

	let newDeparturesList = [
		{
			scheduled: [],
		},
		{
			privatizable: [],
		},
	];

	//Filter for scheduled departures
	let scheduledDepartures;
	scheduledDepartures = departures.filter(
		(departure) =>
			!departure.onlyAvailableToBePrivatized || !departure.privatized
	);

	//Filter for privatizable departures
	let privatizableDepartures;
	privatizableDepartures = departures.filter(
		(departure) =>
			departure.onlyAvailableToBePrivatized || departure.bookedGuests == 0
	);

	//Get scheduled departure years
	scheduledDepartures.map((departure) => {
		if (departure.startDate.getUTCFullYear() < today.getUTCFullYear() + 5) {
			newDeparturesList[0].scheduled.push({
				year: departure.startDate.getUTCFullYear(),
			});
		}
	});

	//Get privatizable departure years
	privatizableDepartures.map((departure) => {
		if (departure.startDate.getUTCFullYear() < today.getUTCFullYear() + 5) {
			newDeparturesList[1].privatizable.push({
				year: departure.startDate.getUTCFullYear(),
			});
		}
	});

	//Return unique year values for scheduled departures
	newDeparturesList[0].scheduled = getUnique(
		newDeparturesList[0].scheduled,
		"year"
	);

	//Return unique year values for privatizable departures
	newDeparturesList[1].privatizable = getUnique(
		newDeparturesList[1].privatizable,
		"year"
	);

	//Push scheduled departures under correct year
	for (let i = 0; i < newDeparturesList[0].scheduled.length; i++) {
		newDeparturesList[0].scheduled[i].dates = [];
		scheduledDepartures.map((departure) => {
			if (
				newDeparturesList[0].scheduled[i].year ==
				departure.startDate.getUTCFullYear()
			) {
				newDeparturesList[0].scheduled[i].dates.push({
					departureId: departure.departureId,
					bookedGuests: departure.bookedGuests,
					price: departure.price,
					singleSupplement: departure.singleSupplement,
					upgradesAvailable: departure.upgradesAvailable,
					guaranteedDeparture: departure.guaranteedDeparture,
					departureItineraryId: departure.departureItineraryId,
					startDate:
						monthNames[departure.startDate.getUTCMonth()] +
						" " +
						departure.startDate.getUTCDate(),
					endDate:
						monthNames[departure.endDate.getUTCMonth()] +
						" " +
						departure.endDate.getUTCDate(),
					tripStartMonth: departure.startDate.getUTCMonth(),
					tripStartDate: departure.startDate.getUTCDate(),
					tripEndMonth: departure.endDate.getUTCMonth(),
					tripEndDate: departure.endDate.getUTCDate(),
					youngAdult: departure.youngAdult,
					privatized: departure.privatized,
					maxGuests: departure.maxGuests,
					isBookable:
						departure.maxGuests === departure.bookedGuests ||
						(departure.privatized == true &&
							departure.bookedGuests > 0)
							? false
							: true,
					onlyAvailableToBePrivatized:
						departure.bookedGuests === 0 &&
						departure.privatized == true
							? true
							: false,
				});
			}
		});
	}

	//Push privatizable departures under correct year
	for (let i = 0; i < newDeparturesList[1].privatizable.length; i++) {
		newDeparturesList[1].privatizable[i].dates = [];
		privatizableDepartures.map((departure) => {
			if (
				newDeparturesList[1].privatizable[i].year ==
				departure.startDate.getUTCFullYear()
			) {
				newDeparturesList[1].privatizable[i].dates.push({
					departureId: departure.departureId,
					bookedGuests: departure.bookedGuests,
					price: departure.price,
					singleSupplement: departure.singleSupplement,
					upgradesAvailable: departure.upgradesAvailable,
					guaranteedDeparture: departure.guaranteedDeparture,
					departureItineraryId: departure.departureItineraryId,
					startDate:
						monthNames[departure.startDate.getUTCMonth()] +
						" " +
						departure.startDate.getUTCDate(),
					endDate:
						monthNames[departure.endDate.getUTCMonth()] +
						" " +
						departure.endDate.getUTCDate(),
					tripStartMonth: departure.startDate.getUTCMonth(),
					tripStartDate: departure.startDate.getUTCDate(),
					tripEndMonth: departure.endDate.getUTCMonth(),
					tripEndDate: departure.endDate.getUTCDate(),
					youngAdult: departure.youngAdult,
					privatized: departure.privatized,
					maxGuests: departure.maxGuests,
					isBookable:
						departure.maxGuests === departure.bookedGuests ||
						(departure.privatized == true &&
							departure.bookedGuests > 0)
							? false
							: true,
					onlyAvailableToBePrivatized:
						departure.bookedGuests === 0 &&
						departure.privatized == true
							? true
							: false,
				});
			}
		});
	}

	return newDeparturesList;
};

const BookingToolRebuild = ({ itinerary, tripData }) => {
	const classes = useStyles();
	const mobileWidth = useMediaQuery("(max-width:960px)");

	//Declare State Variables
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [departuresList, setDeparturesList] = useState(null);
	const [selectedYear, setSelectedYear] = useState(null);
	const [selectedDepartureId, setSelectedDepartureId] = useState(null);
	const [selectedDeparture, setSelectedDeparture] = useState(null);
	const [hasAvailableDepartures, setHasAvailableDepartures] = useState(false);
	const [youngAdultDepartures, setYoungAdultDepartures] = useState(null);
	const [departureType, setDepartureType] = useState("scheduled");
	const [departureYears, setDepartureYears] = useState(null);
	const [scheduledExpanded, setScheduledExpanded] = useState(false);
	const [privatizableExpanded, setPrivatizableExpanded] = useState(false);
	const [currentDeparturesPage, setCurrentDeparturesPage] = useState(1);
	const [departureDatesPerPage] = useState(24);
	const [selectedYoungAdultDeparture, setSelectedYoungAdultDeparture] =
		useState(null);

	//Declare Form Modal State Variables
	const [isPrivatizeDepartureModalOpen, setIsPrivatizeDepartureModalOpen] =
		useState(false);
	const [isBookNowModalOpen, setIsBookNowModalOpen] = useState(false);
	const [isRequestItineraryModalOpen, setIsRequestItineraryModalOpen] =
		useState(false);
	const [isNewsletterSignUpModalOpen, setIsNewsletterSignUpModalOpen] =
		useState(false);

	const handleScheduledPanelChange =
		(panel, departureDate) => (event, isExpanded) => {
			setScheduledExpanded(isExpanded ? panel : false);

			if (departureDate.departureId !== selectedYoungAdultDeparture) {
				setSelectedYoungAdultDeparture(null);
			}
		};

	const handlePrivatizablePanelChange =
		(panel, departureDate) => (event, isExpanded) => {
			setPrivatizableExpanded(isExpanded ? panel : false);

			if (departureDate.departureId !== selectedYoungAdultDeparture) {
				setSelectedYoungAdultDeparture(null);
			}
		};

	const handleDepartureTypeToggle = (event, newDepartureType) => {
		setPrivatizableExpanded(false);
		setScheduledExpanded(false);
		if (newDepartureType !== null) {
			setDepartureType(newDepartureType);
		}
	};

	const handleYearToggle = (event, newYearValue) => {
		if (newYearValue !== null) {
			setSelectedYear(newYearValue);
		}
	};

	const buildDepartureYears = () => {
		if (departuresList) {
			let finalDepartureYears = [];

			//Return unique year values for scheduled departures
			let scheduledDepartureYears = (departuresList[0].scheduled =
				getUnique(departuresList[0].scheduled, "year"));

			//Return unique year values for privatizable departures
			let privatizableDepartureYears = (departuresList[1].privatizable =
				getUnique(departuresList[1].privatizable, "year"));

			let combinedDepartures = scheduledDepartureYears.concat(
				privatizableDepartureYears
			);

			let uniqueDepartureYears = getUnique(combinedDepartures, "year");

			uniqueDepartureYears.map((departureYear) => {
				return finalDepartureYears.push(departureYear.year);
			});

			return finalDepartureYears;
		}
	};

	const countDepartureAvailableSpaces = (departureDate) => {
		if (departureDate.maxGuests - departureDate.bookedGuests <= 3) {
			return true;
		} else {
			return false;
		}
	};

	const renderDepartureTypeToggle = () => {
		return (
			<>
				<ToggleButtonGroup
					value={departureType}
					exclusive
					onChange={handleDepartureTypeToggle}
					aria-label="departure type"
					className={classes.departureTypeToggle}
					style={{
						maxWidth: mobileWidth ? "none" : "465px",
						width: mobileWidth ? "88%" : "100%",
					}}
				>
					<ToggleButton
						value="scheduled"
						aria-label="scheduled departures"
						style={{ width: mobileWidth ? "48%" : "230px" }}
					>
						<Typography
							variant="h1"
							className={classes.departureTypeTitles}
							style={{
								fontSize: mobileWidth ? "16px" : "20px",
							}}
						>
							Join a trip
						</Typography>
						{mobileWidth ? (
							<></>
						) : (
							<Typography
								variant="h1"
								className={classes.departureTypeTitles}
								style={{
									fontSize: "12px",
								}}
							>
								with 16 like-minded travellers
							</Typography>
						)}
					</ToggleButton>
					<ToggleButton
						value="privatizable"
						aria-label="privatizable departures"
						style={{ width: mobileWidth ? "50%" : "230px" }}
					>
						<Typography
							variant="h1"
							className={classes.departureTypeTitles}
							style={{
								fontSize: mobileWidth ? "16px" : "20px",
							}}
						>
							Privatize a trip
						</Typography>
						{mobileWidth ? (
							<></>
						) : (
							<Typography
								variant="h1"
								className={classes.departureTypeTitles}
								style={{
									fontSize: "12px",
								}}
							>
								for your group of 8+ travellers
							</Typography>
						)}
					</ToggleButton>
				</ToggleButtonGroup>
				{mobileWidth ? (
					<Box maxWidth={"91%"} margin={"auto"}>
						<Typography
							variant="h1"
							style={{
								fontSize: "14px",
								textTransform: "none",
								textAlign: "center",
								fontStyle: "italic",
								marginBottom: "1rem",
							}}
						>
							{departureType === "scheduled"
								? "Travel with up to 16 like-minded travellers"
								: "Take over a date for your group of 8+ travellers"}
						</Typography>
					</Box>
				) : (
					<></>
				)}
			</>
		);
	};

	const renderDepartureYearsToggle = () => {
		if (departureYears) {
			return (
				<ToggleButtonGroup
					value={selectedYear}
					exclusive
					onChange={handleYearToggle}
					aria-label="departure year"
					className={classes.departureYearToggle}
				>
					{departureYears.map((year) => {
						return (
							<ToggleButton value={year} aria-label={year}>
								{year}
							</ToggleButton>
						);
					})}
				</ToggleButtonGroup>
			);
		}
	};

	const renderDepartureCalendar = (departure) => {
		const buildDepartureCalendar = (
			initialInactiveDays,
			activeDays,
			finalInactiveDays,
			year,
			startMonth,
			startDate
		) => {
			let calendarArray = [];

			for (let i = 0; i < initialInactiveDays; i++) {
				calendarArray.push(
					<div className={"inactive-departure-day"}>{""}</div>
				);
			}

			for (let j = 0; j < activeDays; j++) {
				const date = new Date(year, startMonth, startDate + j);
				calendarArray.push(
					<div className={"active-departure-day"}>
						{date.getDate()}
					</div>
				);
			}

			for (let k = 0; k < finalInactiveDays; k++) {
				calendarArray.push(
					<div className={"inactive-departure-day"}>{""}</div>
				);
			}

			return calendarArray;
		};

		// Determine if year is leap year
		Date.prototype.isLeapYear = function () {
			var year = this.getUTCFullYear();
			if ((year & 3) != 0) return false;
			return year % 100 != 0 || year % 400 == 0;
		};

		// Get Day of Year
		Date.prototype.getDOY = function () {
			var dayCount = [
				0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334,
			];
			var mn = this.getUTCMonth();
			var dn = this.getUTCDate();
			var dayOfYear = dayCount[mn] + dn;
			if (mn > 1 && this.isLeapYear()) dayOfYear++;
			return dayOfYear;
		};

		if (departure) {
			let year = selectedYear;
			let selectedStartMonth = departure.tripStartMonth;
			let selectedStartDate = departure.tripStartDate;
			let selectedEndMonth = departure.tripEndMonth;
			let selectedEndDate = departure.tripEndDate;
			let startDate;
			let endDate;

			startDate = new Date(year, selectedStartMonth, selectedStartDate);
			endDate = new Date(year, selectedEndMonth, selectedEndDate);

			let initialInactiveDays = startDate.getUTCDay();
			let activeDays = endDate.getDOY() - startDate.getDOY() + 1;
			let finalInactiveDays = 6 - endDate.getUTCDay();

			const daysOfTheWeek = ["S", "M", "T", "W", "T", "F", "S"];

			return (
				<div
					className={classes.departureDays}
					style={{
						maxWidth: mobileWidth ? "175px" : "none",
						margin: mobileWidth ? "auto" : 0,
						paddingTop: mobileWidth ? "25px" : "0px",
					}}
				>
					<div className={classes.daysOfTheWeek}>
						{daysOfTheWeek.map((day) => {
							return (
								<div>
									<span>{day}</span>
								</div>
							);
						})}
					</div>
					<div
						style={{
							display: "flex",
							maxWidth: "150px",
							flexWrap: "wrap",
						}}
					>
						{buildDepartureCalendar(
							initialInactiveDays,
							activeDays,
							finalInactiveDays,
							year,
							selectedStartMonth,
							selectedStartDate
						)}
					</div>
					<Typography
						variant="h1"
						style={{
							fontSize: "15px",
							color: "#494949",
							margin: "10px 0px",
							textTransform: "none",
							textAlign: "left",
						}}
					>
						{tripData?.p15_numtourdays} Days |{" "}
						{tripData?.p15_numtourdays - 1} Nights
					</Typography>
				</div>
			);
		}
	};

	const renderSelectedYearDepartures = () => {
		if (departuresList && departureType && selectedYear) {
			//Determine if departures are scheduled or privatizable
			let departuresByDepartureType =
				departureType === "scheduled"
					? departuresList[0].scheduled
					: departuresList[1].privatizable;

			//Return departures from departureType and selectedYear
			let selectedYearDepartures = where(departuresByDepartureType, {
				year: selectedYear,
			});

			//Define pagination variables
			const indexOfLastDate =
				currentDeparturesPage * departureDatesPerPage;
			const indexOfFirstDate = indexOfLastDate - departureDatesPerPage;

			//Split selectedYearDepartures array by 4
			let currentDates = [];
			if (selectedYearDepartures.length > 0) {
				currentDates = selectedYearDepartures[0].dates.slice(
					indexOfFirstDate,
					indexOfLastDate
				);
			}

			//Render selectedYearDepartures panels
			let scheduledPanelCounter = 0;
			let privatizablePanelCounter = 0;
			let firstAvailablePanelIndex;

			const renderSelectedYearDepartures =
				currentDates.length > 0 ? (
					currentDates.map((departureDate) => {
						let panelIndex;
						let onChange;
						let expandedPanel;

						if (departureType === "scheduled") {
							panelIndex = `panel${scheduledPanelCounter}`;
							onChange = handleScheduledPanelChange(
								panelIndex,
								departureDate
							);
							expandedPanel = scheduledExpanded;
							scheduledPanelCounter++;
						} else if (departureType === "privatizable") {
							panelIndex = `panel${privatizablePanelCounter}`;
							onChange = handlePrivatizablePanelChange(
								panelIndex,
								departureDate
							);
							expandedPanel = privatizableExpanded;
							privatizablePanelCounter++;
						}

						//Automatically expand the accordion panel with the first
						//available departure on the current page of sliced departure dates
						if (
							!firstAvailablePanelIndex &&
							departureDate.bookedGuests <
								departureDate.maxGuests &&
							!departureDate.privatized
						) {
							firstAvailablePanelIndex = panelIndex;
						}

						return (
							<Accordion
								expanded={
									expandedPanel === panelIndex
									//  ||
									// (!expandedPanel &&
									//     firstAvailablePanelIndex === panelIndex)
								}
								onChange={onChange}
								key={departureDate.departureId}
								id={departureDate.departureId}
								disabled={
									departureDate.maxGuests ==
										departureDate.bookedGuests ||
									(departureDate.privatized &&
										!departureDate.onlyAvailableToBePrivatized)
										? true
										: false
								}
								className={classes.departurePanel}
								style={{
									border:
										selectedYoungAdultDeparture ===
										departureDate.departureId
											? "2px solid #6BB3C7"
											: "1px solid #e6e6e6",
								}}
							>
								<AccordionSummary
									className={classes.panelLabel}
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1a-content"
									id="panel1a-header"
								>
									<Grid container>
										<Grid
											item
											xs={6}
											style={{ textAlign: "left" }}
										>
											{departureDate.startDate +
												" - " +
												departureDate.endDate}
										</Grid>
										<Grid
											item
											xs={6}
											style={{ display: "flex" }}
										>
											<Grid container spacing={0}>
												<Grid
													item
													xs={2}
													style={{
														textAlign: "center",
													}}
												>
													<span
														className={
															classes.departureFlagCircle
														}
														style={{
															background:
																departureDate.maxGuests ==
																departureDate.bookedGuests
																	? "#C4635F"
																	: departureDate.privatized &&
																	  !departureDate.onlyAvailableToBePrivatized
																	? "#C4635F"
																	: countDepartureAvailableSpaces(
																			departureDate
																	  )
																	? "#EADFB0"
																	: "#94B18E",
														}}
													></span>
													{departureDate.youngAdult ? (
														<span
															className={
																classes.departureFlagCircle
															}
															style={{
																background:
																	"#6BB3C7",
															}}
														></span>
													) : (
														<></>
													)}
												</Grid>
												<Grid item xs={10}>
													<div
														style={{
															fontSize:
																mobileWidth
																	? "12.5px"
																	: "13.5px",
															textAlign: "left",
														}}
													>
														{departureDate.maxGuests ==
															departureDate.bookedGuests ||
														(departureDate.privatized &&
															!departureDate.onlyAvailableToBePrivatized)
															? "No space available"
															: countDepartureAvailableSpaces(
																	departureDate
															  )
															? "Limited space left"
															: "Space available"}
													</div>
													{departureDate.youngAdult ? (
														<div
															style={{
																fontSize:
																	mobileWidth
																		? "12.5px"
																		: "14px",
																marginTop:
																	mobileWidth
																		? "3px"
																		: "1px",
															}}
														>
															Young adult friendly
														</div>
													) : (
														<></>
													)}
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</AccordionSummary>
								<AccordionDetails style={{ display: "block" }}>
									<Grid
										container
										style={{
											maxWidth: mobileWidth
												? "none"
												: "370px",
											margin: "auto",
										}}
									>
										<Grid
											item
											xs={12}
											sm={6}
											style={{
												justifyContent: "center",
												display: "block",
												marginTop: mobileWidth
													? "0px"
													: "30px",
											}}
										>
											{mobileWidth ? (
												departureDate.youngAdult ? (
													<div
														style={{
															borderBottom:
																"1px solid #e6e6e6",
														}}
													>
														<Typography
															variant="h1"
															style={{
																fontSize:
																	"13.5px",
																color: "#494949",
																margin: "2px 0px 8px 0px",
																textTransform:
																	"none",
																padding:
																	mobileWidth
																		? "0px 20px"
																		: "0px",
															}}
														>
															Great for families
															with kids in their
															late teens and up
														</Typography>
													</div>
												) : (
													<></>
												)
											) : (
												<></>
											)}
											{renderDepartureCalendar(
												departureDate
											)}
										</Grid>
										<Grid
											item
											xs={12}
											sm={6}
											style={{
												maxWidth: mobileWidth
													? "175px"
													: "none",
												margin: "auto",
												paddingTop: "20px",
												textAlign: "left",
											}}
										>
											{departureDate.youngAdult ? (
												<div
													style={{
														borderBottom:
															"1px solid #e6e6e6",
														display: mobileWidth
															? "none"
															: "block",
													}}
												>
													<Typography
														variant="h1"
														style={{
															fontSize: "13.5px",
															color: "#494949",
															margin: "2px 0px 8px 0px",
															textTransform:
																"none",
														}}
													>
														Great for families with
														kids in their late teens
														and up
													</Typography>
												</div>
											) : (
												<></>
											)}

											{departureType === "scheduled" ? (
												<>
													<Typography
														variant="h1"
														style={{
															fontSize: "24px",
															color: "#00416B",
														}}
													>
														{departureDate.price}{" "}
														USD
													</Typography>
													<Typography
														variant="h1"
														style={{
															fontSize: "13px",
															color: "#494949",
															textTransform:
																"none",
															marginTop: "5px",
														}}
													>
														Per person, +
														{
															departureDate.singleSupplement
														}{" "}
														Single supplement
													</Typography>
												</>
											) : (
												<Typography
													variant="h1"
													style={{
														fontSize: "13px",
														color: "#494949",
														textTransform: "none",
														marginTop: "5px",
													}}
												>
													Pricing based on group size
												</Typography>
											)}
											<Button
												onClick={() => {
													setSelectedDeparture(
														departureDate
													);
													setSelectedDepartureId(
														departureDate.departureId
													);

													departureType ===
													"scheduled"
														? setIsBookNowModalOpen(
																!isBookNowModalOpen
														  )
														: setIsPrivatizeDepartureModalOpen(
																!isPrivatizeDepartureModalOpen
														  );
												}}
												className={classes.bookCta}
											>
												{departureType === "scheduled"
													? "Book Now"
													: "Privatize Date"}
											</Button>
										</Grid>
									</Grid>
								</AccordionDetails>
							</Accordion>
						);
					})
				) : (
					//Render if there are no available departures
					//for the selected departureType and selectedYear
					<div>
						<Typography
							variant="h1"
							style={{
								fontSize: "16px",
								textTransform: "none",
								textAlign: "center",
								fontStyle: "italic",
							}}
						>
							Unfortunately there are no remaining dates available
							to be privatized in {selectedYear}.
						</Typography>
						{/* <Typography
                            variant="h1"
                            style={{
                                fontSize: "16px",
                                color: "#494949",
                                textTransform: "none",
                                lineHeight: 1.5,
                                textAlign: "center",
                                fontStyle: "italic",
                                marginTop: "15px",
                            }}
                        >
                            If you can’t find your dates, we can design a{" "}
                            <span className={classes.bespokeTripCta}>
                                <Link
                                    href={`/trip/bespoke/${tripData.cms_go_bespoke_trip}`}
                                >
                                    bespoke
                                </Link>
                            </span>{" "}
                            trip for you!
                        </Typography> */}
						<Typography
							variant="h1"
							style={{
								fontSize: "16px",
								textTransform: "none",
								marginTop: "15px",
								textAlign: "center",
								fontStyle: "italic",
							}}
						>
							Click{" "}
							<span className={classes.bespokeTripCta}>
								<Button
									className={
										classes.newsletterSignupCta +
										" newsletter-signup-cta"
									}
									onClick={() =>
										setIsNewsletterSignUpModalOpen(
											!isNewsletterSignUpModalOpen
										)
									}
								>
									here
								</Button>
							</span>{" "}
							to join our mailing list to receive new trip
							updates, special announcements and more.
						</Typography>
					</div>
				);

			//Push page numbers into array
			let pageNumbers = [];
			if (selectedYearDepartures.length > 0) {
				if (selectedYearDepartures[0].dates.length > 4) {
					for (
						let i = 1;
						i <=
						Math.ceil(
							selectedYearDepartures[0].dates.length /
								departureDatesPerPage
						);
						i++
					) {
						pageNumbers.push(i);
					}
				}
			}

			//Render page numbers from pageNumbers array
			let renderPageNumbers;
			if (pageNumbers.length > 0) {
				renderPageNumbers = pageNumbers.map((number) => (
					<li
						key={number}
						onClick={() => {
							setCurrentDeparturesPage(number);
							setScheduledExpanded(false);
							setPrivatizableExpanded(false);
						}}
						className={
							currentDeparturesPage === number ? "active" : null
						}
					>
						{number}
					</li>
				));
			}

			return (
				<>
					<div
						style={{
							padding: mobileWidth ? "25px 20px" : "25px 0px",
							maxWidth: "461px",
							margin: "auto",
							width: "100%",
							position: "relative",
							// minHeight: mobileWidth ? "330px" : "500px",
						}}
						className={classes.yearDeparturesContainer}
					>
						{renderSelectedYearDepartures}
					</div>

					{/* {selectedYearDepartures.length > 0 && renderPageNumbers ? (
                        <ul
                            className={classes.pagination}
                            style={{
                                position: mobileWidth ? "static" : "absolute",
                                transform: mobileWidth
                                    ? "none"
                                    : "translate(-50%)",
                                paddingBottom: mobileWidth ? "30px" : "0px",
                            }}
                        >
                            {renderPageNumbers}
                        </ul>
                    ) : (
                        <></>
                    )} */}
				</>
			);
		}
	};

	const buildYoungAdultDeparturesSection = () => {
		let youngAdultDepartures = [];
		let tripDepartures = tripData?.departures;

		//Set Month Names in 'mmm' Format
		const monthNames = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];

		if (tripDepartures.length > 0) {
			//Sort departures by start date
			tripDepartures.sort((a, b) => {
				let fa = a.p15_startdate,
					fb = b.p15_startdate;

				if (fa < fb) {
					return -1;
				}
				if (fa > fb) {
					return 1;
				}
				return 0;
			});
		}

		const buildYoungAdultDeparturesArray = () => {
			for (let i = 0; i < tripDepartures.length; i++) {
				let departure = tripDepartures[i];

				if (departure.br_youngadult) {
					youngAdultDepartures.push({
						startDate:
							monthNames[
								new Date(departure.p15_startdate).getUTCMonth()
							] +
							" " +
							new Date(departure.p15_startdate).getUTCDate(),
						endDate:
							monthNames[
								new Date(departure.p15_enddate).getUTCMonth()
							] +
							" " +
							new Date(departure.p15_enddate).getUTCDate() +
							", " +
							new Date(departure.p15_startdate).getFullYear(),
						departureYear: new Date(
							departure.p15_startdate
						).getFullYear(),
						departureId: departure.p15_tripdeparturesid,
						bookedGuests: departure.p15_bookedguestcount,
						singleSupplement:
							departure.cms_departure_single_supplement,
						upgradesAvailable: departure.br_suiteupgradesavailable,
						guaranteedDeparture: departure.br_guaranteeddeparture,
						price: departure.p15_fromprice,
						departureItineraryId: departure.br_departureitineraryid,
						privatized: departure.br_blockeddeparture,
						maxGuests: departure.p15_maxguests,
						tripStartMonth: new Date(
							departure.p15_startdate
						).getUTCMonth(),
						tripStartDate: new Date(
							departure.p15_startdate
						).getUTCDate(),
						tripEndMonth: new Date(
							departure.p15_enddate
						).getUTCMonth(),
						tripEndDate: new Date(
							departure.p15_enddate
						).getUTCDate(),
					});
				}
			}
			return youngAdultDepartures;
		};

		return buildYoungAdultDeparturesArray().length > 0 ? (
			<>
				<div
					style={{
						background: "#6BB3C7",
						marginTop: "35px",
						borderRadius: "10px",
						padding: "0px 15px",
					}}
				>
					<Container
						maxWidth={"md"}
						className={classes.youngAdultDepartures}
					>
						<Grid container spacing={3}>
							<Grid
								item
								xs={12}
								sm={6}
								style={{
									paddingBottom: mobileWidth ? 0 : "30px",
								}}
							>
								<Typography
									variant="h1"
									style={{
										textTransform: "none",
										fontSize: "20px",
										margin: "12px 0",
										color: "#494949",
										textAlign: mobileWidth
											? "center"
											: "left",
									}}
								>
									What is a Young Adult Departure?
								</Typography>

								<Grid
									container
									spacing={2}
									style={{
										marginTop: mobileWidth ? "15px" : 0,
									}}
								>
									{youngAdultDepartures.map(
										(departure, i) => {
											return (
												<Grid
													item
													xs={12}
													sm={6}
													md={6}
												>
													<Button
														key={
															departure.departureId
														}
														id={
															departure.departureId
														}
														aria-label={
															departure.startDate +
															" - " +
															departure.endDate
														}
														className={
															classes.youngAdultDepartureButton +
															" " +
															(selectedDepartureId ===
															departure.departureId
																? classes.buttonSelected
																: "")
														}
														onClick={(e) => {
															setSelectedYoungAdultDeparture(
																e.currentTarget
																	.id
															);
															// scrollToTarget(
															// 	"booking-tool",
															// 	mobileWidth
															// );
														}}
													>
														{departure.startDate} -{" "}
														{departure.endDate}
													</Button>
												</Grid>
											);
										}
									)}
								</Grid>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Typography
									variant="body1"
									style={{
										fontSize: "16px",
										marginTop: "7px",
										marginBottom: "10px",
									}}
								>
									Share the joy of travel with the family!
									These are special departures designed to
									accommodate families with young adults (late
									teens and up). Perfect for your
									university-aged kids to join you on
									vacation.
								</Typography>
							</Grid>
						</Grid>
					</Container>
				</div>
			</>
		) : (
			""
		);
	};

	useEffect(() => {
		//Build departures array
		if (tripData?.departures.length > 0) {
			setDeparturesList(buildDepartureData(tripData.departures));
			setIsLoading(false);

			//Check if trip has Young Adult Departures available
			const hasYoungAdultDepartures = tripData.departures.some(
				(departure) => departure.br_youngadult
			);
			//Set Young Adult Departures
			if (hasYoungAdultDepartures && youngAdultDepartures === null) {
				setYoungAdultDepartures(hasYoungAdultDepartures);
			}
		}
	}, [tripData]);

	useEffect(() => {
		//Set Departure Years
		// if (departureYears === null && departuresList) {
		if (departuresList) {
			setDepartureYears(buildDepartureYears());
		}
	}, [departuresList]);

	useEffect(() => {
		//Set Initial State for Selected Year
		// if (selectedYear === null && departureYears) {
		if (departureYears) {
			setSelectedYear(departureYears[0]);
		}
	}, [departureYears]);

	return (
		<div
			id="choose_departure"
			style={{ background: "#494949", padding: "3rem 0 3.5rem 0" }}
		>
			<Container
				maxWidth={"md"}
				style={{
					paddingTop: mobileWidth ? "1rem" : "2rem",
					paddingBottom: mobileWidth ? "2rem" : "2rem",
				}}
			>
				<h3
					className={classes.tripDatesTitle}
					style={{
						width: mobileWidth ? "100%" : "40%",
						color: "#fff",
					}}
				>
					Select your trip dates
				</h3>
				<Typography
					variant="body1"
					className={classes.text}
					style={{
						marginBottom: "15px",
						color: "#fff",
						fontSize: 14,
					}}
				>
					NOTE: The Itinerary above is a sample and subject to
					changes. A departure-specific itinerary will be shared with
					you upon date selection.
				</Typography>
				<Paper
					elevation={6}
					style={{
						padding: 0,
					}}
				>
					<Grid
						container
						spacing={0}
						className={classes.tripDeparturesContainer}
					>
						<Grid
							item
							xs={12}
							sm={12}
							md={8}
							style={{
								flex: mobileWidth ? "initial" : 1,
								width: "100%",
								// height: mobileWidth ? "100%" : "785px",
								position: "relative",
							}}
						>
							<h3 className={classes.bookingToolsTitle}>
								Find Your Trip
							</h3>
							{renderDepartureTypeToggle()}
							{renderDepartureYearsToggle()}
							{renderSelectedYearDepartures()}
							{tripData.cms_go_bespoke_trip ? (
								<div
									style={{
										maxWidth: "459px",
										margin: "auto",
										paddingBottom: "30px",
									}}
								>
									<Typography
										variant="h1"
										style={{
											fontSize: "14.5px",
											color: "#494949",
											textTransform: "none",
											lineHeight: 1.5,
											marginTop: mobileWidth
												? "15px"
												: "0px",
											padding: mobileWidth
												? "0px 20px"
												: "0px",
										}}
									>
										Can’t find your dates? We can design a{" "}
										<span
											className={classes.bespokeTripCta}
										>
											<a
												href={`https://www.butterfield.com/trip/bespoke/${tripData.cms_go_bespoke_trip}`}
												style={{ fontWeight: 300 }}
												title="Bespoke"
												target="_blank"
											>
												Bespoke
											</a>
										</span>{" "}
										trip for you!
									</Typography>
								</div>
							) : (
								<></>
							)}
						</Grid>
						<Grid
							item
							xs={0}
							sm={0}
							md={4}
							style={{
								flex: mobileWidth ? "unset" : 1,
								display: mobileWidth ? "none" : "initial",
							}}
							className={classes.imageGrid}
						>
							<div
								style={{
									width: "100%",
									height: "100%",
									position: "relative",
									minWidth: mobileWidth ? "0px" : "305px",
								}}
							>
								<img
									src={
										itinerary.cover_images
											? `https://wetu.com/ImageHandler/c833x500/${itinerary.cover_images[0].url_fragment}`
											: `${tripData.cms_departure_photo}`
									}
									className={classes.departureImage}
								/>
							</div>
						</Grid>
					</Grid>
				</Paper>

				{youngAdultDepartures ? (
					buildYoungAdultDeparturesSection()
				) : (
					<></>
				)}
			</Container>

			<BrIframeModal
				fullScreen={true}
				isOpen={isBookNowModalOpen}
				background={true}
				padding={true}
				previousPageHeading={"Return to previous page"}
				handleClick={() => setIsBookNowModalOpen(!isBookNowModalOpen)}
			>
				<MarketoForm
					additionalData={{
						departureDate:
							selectedDeparture?.startDate +
							"-" +
							selectedDeparture?.endDate +
							", " +
							selectedYear,
						tripName: tripData?.p15_tripname,
						tripId: tripData?.p15_tripsid,
						selectedDepartureId: selectedDepartureId,
						closeForm: () =>
							setIsBookNowModalOpen(!isBookNowModalOpen),
					}}
					form="smallGroupBookNow"
				/>
			</BrIframeModal>
			<BrIframeModal
				fullScreen={true}
				isOpen={isRequestItineraryModalOpen}
				background={true}
				padding={true}
				previousPageHeading={"Return to trip page"}
				handleClick={() =>
					setIsRequestItineraryModalOpen(!isRequestItineraryModalOpen)
				}
			>
				<div className={"small-group-request-itinerary"}>
					<MarketoForm
						form="requestItinerary"
						additionalData={{
							formHeading: tripData?.p15_tripname,
							tripType: "Scheduled Departure",
							tripName: tripData?.p15_tripname,
							tripInfo: tripData,
							finalDepartureList:
								departureType === "scheduled"
									? departuresList?.[0].scheduled
									: departuresList?.[1].privatizable,
							selectedDepartureDate:
								selectedDeparture?.startDate +
								" - " +
								selectedDeparture?.endDate +
								", " +
								selectedYear,
							closeForm: () =>
								setIsRequestItineraryModalOpen(
									!isRequestItineraryModalOpen
								),
						}}
					/>
				</div>
			</BrIframeModal>
			<BrIframeModal
				fullScreen={true}
				isOpen={isPrivatizeDepartureModalOpen}
				background={true}
				padding={true}
				previousPageHeading={"Return to previous page"}
				handleClick={() =>
					setIsPrivatizeDepartureModalOpen(
						!isPrivatizeDepartureModalOpen
					)
				}
			>
				<MarketoForm
					additionalData={{
						departureDate:
							selectedDeparture?.startDate +
							" - " +
							selectedDeparture?.endDate +
							", " +
							selectedYear,
						tripName: tripData?.p15_tripname,
						tripId: tripData?.p15_tripsid,
						selectedDepartureId: selectedDepartureId,
						finalDepartureList: departuresList?.[1].privatizable,
						itineraryId: selectedDeparture?.departureItineraryId,
						closeForm: () =>
							setIsPrivatizeDepartureModalOpen(
								!isPrivatizeDepartureModalOpen
							),
					}}
					form="privatizeDeparture"
				/>
			</BrIframeModal>
			<BrIframeModal
				fullScreen={false}
				isOpen={isNewsletterSignUpModalOpen}
				addClassName={"wider"}
				handleClick={() =>
					setIsNewsletterSignUpModalOpen(!isNewsletterSignUpModalOpen)
				}
			>
				<div
					className={"newsletter-sign-up"}
					style={{ position: "relative" }}
				>
					<div
						onClick={() =>
							setIsNewsletterSignUpModalOpen(
								!isNewsletterSignUpModalOpen
							)
						}
					>
						<CloseIcon
							style={{
								color: "#194666",
								position: "absolute",
								right: "10px",
								top: "10px",
								cursor: "pointer",
							}}
						/>
					</div>
					<MarketoForm
						form="newsletterSignUp"
						additionalData={{
							closeForm: () =>
								setIsNewsletterSignUpModalOpen(
									!isNewsletterSignUpModalOpen
								),
						}}
					/>
				</div>
			</BrIframeModal>
		</div>
	);
};

export default BookingToolRebuild;
